import { NgModule } from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';
import {IntelligenceComponent} from "./views/intelligence/intelligence.component";

const routes: Routes = [
  { path: '',pathMatch: 'full' , loadComponent: () => import('./views/accueil/accueil.component').then(m => m.AccueilComponent)},
  { path: 'conseil' , loadComponent: () => import('./views/conseil/conseil.component').then(m => m.ConseilComponent)},
  { path: 'nous' , loadComponent: () => import('./views/nous/nous.component').then(m => m.NousComponent)},
  { path: 'references' , loadComponent: () => import('./views/references/references.component').then(m => m.ReferencesComponent)},
  { path: 'factory' , loadComponent: () => import('./views/factory/factory.component').then(m => m.FactoryComponent)},
  { path: 'contact' , loadComponent: () => import('./views/contact/contact.component').then(m => m.ContactComponent)},
  { path: 'ai-engine' , loadComponent: () => import('./views/intelligence/intelligence.component').then(m => m.IntelligenceComponent)},
  { path: '**' , redirectTo  :''},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes , {
    preloadingStrategy: NoPreloading,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
