import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import {HeaderComponent} from "./standalones/header/header.component";
import {FooterComponent} from "./standalones/footer/footer.component";


@NgModule({
  declarations: [
    AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        HeaderComponent,
        FooterComponent,
    ],
  providers: [
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
