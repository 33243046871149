import {Component, Input} from '@angular/core';
import {TabletMobileTemplateDirective} from "../../services/directives/tablet-mobile-template.directive";
import {NgClass, NgOptimizedImage} from "@angular/common";
import {WebTemplateDirective} from "../../services/directives/web-template.directive";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    TabletMobileTemplateDirective,
    NgOptimizedImage,
    WebTemplateDirective,
    NgClass
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent   {
  isWhiteLogo = true;
  routes = ["/conseil", "/contact", "/factory","/ai-engine"]
  @Input() route =""
  @Input() isHomePage =true;
  date = new Date().getFullYear() ;
  ngOnChanges() {
    this.isWhiteLogo = this.routes
      .some(route => {
        return this.route.startsWith(route)  && this.route !="/"
      });
  }
}
